const EmotionsEnum = {
  sad: 1,
  1: "Sad",
  stressed: 2,
  2: "Stressed",
  pain: 3,
  3: "Pain",
  angry: 4,
  4: "Angry",
  tired: 5,
  5: "Tired",
  relaxed: 6,
  6: "Relaxed",
  inLove: 7,
  7: "Excited",
  depressed: 8,
  8: "Depressed",
  scared: 9,
  9: "Anxious",
  nervous: 10,
  10: "Nervous",
  happy: 11,
  11: "Happy",
  ateTooMuch: 12,
  12: "AteTooMuch"
};

export default EmotionsEnum;
