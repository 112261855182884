import React, { useContext, useState, useEffect } from "react";
import MoodyPoo from "./MoodyPoo";
import colorsEnum from "./colorsEnum";
import { AuthContext } from "./Auth.js";
import { Modal } from "react-materialize";
import { Link, withRouter, Redirect } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "react-loader-spinner";
import DetailsInfo from "./DetailsInfo";
import { months } from "./Utils";
import Language from "./Language";

export const renderDates = (data, begin, end, reverse) => {
  const dataOrdered = reverse
    ? data && data.slice(begin, end).reverse()
    : data && data.slice(begin, end);
  return (
    dataOrdered &&
    dataOrdered.map(rec => {
      const dat = rec.createdAt.toDate();
      const day = dat.getDate();
      const month = months[dat.getMonth()];
      return (
        <div key={rec.createdAt}>
          {day} {month}
        </div>
      );
    })
  );
};

export const renderMoodyPoo = (data, begin, end, reverse) => {
  const dataOrdered = reverse
    ? data && data.slice(begin, end).reverse()
    : data && data.slice(begin, end);
  return (
    dataOrdered &&
    dataOrdered.map(rec => {
      return (
        <MoodyPoo
          key={rec.createdAt.toDate().getTime()}
          mood={{ id: rec.moodId }}
          shape={{ id: rec.shapeId }}
          color={{ id: colorsEnum[rec.colorId.toLowerCase()] }}
        />
      );
    })
  );
};

const Results = props => {
  const { deletePoo } = props;
  const { data, setPoo, user } = useContext(AuthContext);
  const [pooObject, setPooObject] = useState(null);
  const [redirect, setRedirect] = useState({ redirect: false, type: "edit" });
  const [showDialog, setShowDialog] = useState(false);
  const [showOK, setShowOK] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const filtered =
    data &&
    data.filter(res => {
      return res.moodId;
    });

  const onModalInfoHandler = data => {
    const poo = {
      key: data.key,
      moodId: data.moodId,
      createdAt: data.createdAt
    };
    if (data.shapeId) poo.shapeId = data.shapeId;
    if (data.colorId) poo.colorId = data.colorId;
    if (data.details) poo.details = data.details;
    if (data.location) poo.location = data.location;
    if (data.weather) poo.weather = data.weather;
    setPooObject(poo);
    setShowModal(true);
  };

  const renderMoodyPooWithModalLink = (data, begin, end, reverse) => {
    const dataOrdered = reverse
      ? data && data.slice(begin, end).reverse()
      : data && data.slice(begin, end);
    return (
      dataOrdered &&
      dataOrdered.map(rec => {
        return (
          <a
            key={rec.createdAt.toDate().getTime()}
            href="#modalInfo"
            className="modal-trigger"
            onClick={() => onModalInfoHandler(rec)}
          >
            <MoodyPoo
              mood={{ id: rec.moodId }}
              shape={{ id: rec.shapeId ? rec.shapeId : 0 }}
              color={{
                id: rec.colorId ? colorsEnum[rec.colorId.toLowerCase()] : "#fff"
              }}
            />
          </a>
        );
      })
    );
  };

  const renderAll = () => {
    let rows = [];
    if (filtered) {
      const maxLen = filtered.length < 61 ? filtered.length : 60;
      for (let index = 0; index < maxLen; index = index + 5) {
        rows.push(
          <React.Fragment key={index}>
            <div className="result_grid">
              {renderMoodyPooWithModalLink(filtered, index, index + 5)}
            </div>
            <div className="result_grid_labels">
              {renderDates(filtered, index, index + 5)}
            </div>
          </React.Fragment>
        );
      }
    } else return "No data yet...";

    return rows.length > 0 ? (
      rows
    ) : (
      <Loader className="loader" type="TailSpin" />
    );
  };

  const onEditHandler = id => {
    setPoo(pooObject);
    setRedirect({ redirect: true, type: "edit" });
  };

  const onDetailsHandler = id => {
    setPoo(pooObject);
    setRedirect({ redirect: true, type: "details" });
  };

  useEffect(() => {
    if (!user) setRedirect({ redirect: true });
  }, [user]);

  if (redirect.redirect) {
    switch (redirect.type) {
      case "edit":
        return pooObject.shapeId ? (
          <Redirect
            to={{
              pathname: "/editpoo/" + pooObject.key,
              id: "results"
            }}
          />
        ) : (
          <Redirect to={{ pathname: "/editmood", id: "results" }} />
        );
      case "details":
        return (
          <Redirect
            to={{
              pathname: "/details/" + pooObject.key,
              id: "results"
            }}
          />
        );
      default:
        return <Redirect to="/" />;
    }
  }

  const onDeleteHandler = id => {
    setShowDialog(false);
    setShowOK(true);
    deletePoo(id);
  };

  const onCancelHandler = () => {
    setShowDialog(false);
  };

  const onOK = () => {
    setShowOK(false);
    setShowModal(false);
  };

  return (
    <React.Fragment>
      <div className="linkContainer">
        <Link to="/">
          <i className="material-icons big">chevron_left</i>
        </Link>
        <h6>
          <Language topic="ui" id="NavResults" />
        </h6>
        <Link
          to={{
            pathname: "/info",
            id: "pooresults"
          }}
        >
          <i className="material-icons big">info_outline</i>
        </Link>
      </div>
      <div className="container" id="results">
        {renderAll()}
      </div>
      <Modal
        header="poo results info"
        bottomSheet
        id="modalInfo"
        open={showModal}
      >
        <div id="modalInfo--PooInfo">
          {pooObject ? <DetailsInfo pooObject={pooObject} /> : null}
        </div>
        <div id="editDelete">
          {/* eslint-disable-next-line */}
          <a onClick={() => onEditHandler(pooObject.key)}>EDIT</a>
          {" | "}
          {/* eslint-disable-next-line */}
          <a onClick={() => setShowDialog(true)}>DELETE</a>
          {" | "}
          {/* eslint-disable-next-line */}
          <a onClick={() => onDetailsHandler(pooObject.key)}>MORE DETAILS</a>
        </div>
      </Modal>
      <SweetAlert
        show={showDialog}
        warning
        showCancel
        confirmBtnText="Yes, delete it"
        title="Are you sure?"
        onConfirm={() => onDeleteHandler(pooObject.key)}
        onCancel={() => onCancelHandler()}
      >
        You will not be able to recover this record!
      </SweetAlert>
      <SweetAlert
        show={showOK}
        success
        title="Record deleted!"
        onConfirm={() => onOK()}
      ></SweetAlert>
    </React.Fragment>
  );
};

export default withRouter(Results);
