import React, { useContext } from "react";
import { getDate } from "./Utils";
import colorsEnum from "./colorsEnum";
import shapesEnum from "./shapesEnum";
import MoodyPoo from "./MoodyPoo";
import { AuthContext } from "./Auth.js";
import { getLanguageText } from "./Language";

export const RenderDetailsEmos = (pooObject, language = "en") => {
  const emoArray = RenderDetailsEmosArray(pooObject, true, language);
  return emoArray
    ? getLanguageText("ui", "Reasons", language) + emoArray
    : null;
};

export const RenderDetailsEmosArray = (
  pooObject,
  lowerCase = false,
  language = "en"
) => {
  let emos = [];
  let result = null;
  pooObject.details &&
    pooObject.details.emotions.forEach(emotion => {
      emos.push(" " + getLanguageText("emotions", emotion, language));
    });
  const emoString =
    pooObject.details && pooObject.details.emotions.length > 0 && emos.join();
  result = emoString ? emoString : null;
  if (emos.length > 1) {
    const last = emoString.lastIndexOf(",");
    result =
      emoString.substring(0, last) +
      getLanguageText("ui", "and", language) +
      emos[emos.length - 1];
  }
  if (lowerCase && result) result = result.toLowerCase();
  return result;
};

export const RenderDetailsFreeText = pooObject => {
  const details = pooObject.details;
  if (details && details.freeText && details.freeText.value !== "") {
    return "Details: " + details.freeText.value;
  }
};

const DetailsInfo = ({ pooObject }) => {
  const { language } = useContext(AuthContext);
  if (!pooObject) return "loading";

  return (
    <React.Fragment>
      <MoodyPoo
        mood={{ id: pooObject.moodId }}
        shape={{ id: pooObject.shapeId ? pooObject.shapeId : 0 }}
        color={{
          id: pooObject.colorId
            ? colorsEnum[pooObject.colorId.toLowerCase()]
            : "#fff"
        }}
      />
      <div className="modalInfo">
        <div className="modalInfo--header">
          <div className="modalInfo--header-title">
            {pooObject.shapeId ? "POO" : "MOOD"}
          </div>
          <div>{`${getDate(pooObject.createdAt)}`}</div>
        </div>
        <div>
          {pooObject.shapeId &&
            `${shapesEnum[pooObject.shapeId]} ${pooObject.colorId}`}
        </div>
        <div>
          {` ${getLanguageText("ui", "Feeling", language)} ${getLanguageText(
            "mood",
            pooObject.moodId,
            language
          )}`}
        </div>
        <div>{RenderDetailsEmos(pooObject, language)}</div>
        <div>{RenderDetailsFreeText(pooObject)}</div>
      </div>
    </React.Fragment>
  );
};

export default DetailsInfo;
