import React, { useContext } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { AuthContext } from "./Auth.js";

export let maxIsReached = false;

const MaxReached = props => {
  const { maxRecords } = useContext(AuthContext);
  if (maxRecords > 10) {
    maxIsReached = true;
  }
  return (
    <SweetAlert
      show={props.show}
      warning
      title="Maximum reached"
      onConfirm={() => props.setModal(false)}
    >
      Maximum number of submits per day (=10) reached
    </SweetAlert>
  );
};

export default MaxReached;
