import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "./Auth.js";
import Chart from "react-apexcharts";
import emotionsEnum from "./emotionsEnum";
import { getLanguageText } from "./Language";

const EmotionsBubleChart = props => {
  const { css } = props;
  const { data, language } = useContext(AuthContext);
  const [emotions, setEmotions] = useState(null);

  const emotionsChart = {
    options: {
      chart: {
        width: "100%",
        toolbar: {
          show: false
        }
      },
      title: {
        text: getLanguageText("ui", "MoodDetails", language)
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        opacity: 0.8
      },
      tooltip: {
        enabled: false
      },
      xaxis: {
        tickAmount: 11,
        labels: {
          rotate: -45,
          formatter: val => {
            let emo = "";
            switch (val) {
              case 1:
                emo = getLanguageText("emotions", emotionsEnum.sad, language);
                break;
              case 2:
                emo = getLanguageText(
                  "emotions",
                  emotionsEnum.stressed,
                  language
                );
                break;
              case 3:
                emo = getLanguageText("emotions", emotionsEnum.pain, language);
                break;
              case 4:
                emo = getLanguageText("emotions", emotionsEnum.angry, language);
                break;
              case 5:
                emo = getLanguageText("emotions", emotionsEnum.tired, language);
                break;
              case 6:
                emo = getLanguageText(
                  "emotions",
                  emotionsEnum.relaxed,
                  language
                );
                break;
              case 7:
                emo = getLanguageText(
                  "emotions",
                  emotionsEnum.inLove,
                  language
                );
                break;
              case 8:
                emo = getLanguageText(
                  "emotions",
                  emotionsEnum.depressed,
                  language
                );
                break;
              case 9:
                emo = getLanguageText(
                  "emotions",
                  emotionsEnum.scared,
                  language
                );
                break;
              case 10:
                emo = getLanguageText(
                  "emotions",
                  emotionsEnum.nervous,
                  language
                );
                break;
              case 11:
                emo = getLanguageText("emotions", emotionsEnum.happy, language);
                break;
              case 12:
                emo = getLanguageText(
                  "emotions",
                  emotionsEnum.ateTooMuch,
                  language
                );
                break;
              default:
                emo = val;
                break;
            }
            return emo;
          }
        }
      },
      yaxis: {
        tickAmount: 5,
        labels: {
          formatter: value => {
            let ret = "";
            switch (value) {
              case 1:
                ret = "bad ";
                break;
              case 2:
                ret = "not good";
                break;
              case 3:
                ret = "okay";
                break;
              case 4:
                ret = "good";
                break;
              case 5:
                ret = "very good";
                break;
              default:
                ret = "";
                break;
            }
            return ret;
          }
        }
      }
    },
    series: [
      {
        name: "Bubble1",
        data: emotions ? emotions : [1, 1, 1]
      }
    ]
  };

  const renderChart = () => {
    const items = [];

    for (let m = 1; m < 6; m++) {
      for (let e = 1; e < 13; e++) {
        const all =
          data &&
          data.filter(res => {
            return (
              res.moodId === m &&
              res.details &&
              res.details.emotions &&
              res.details.emotions.includes(e)
            );
          });
        if (all) {
          items.push([e, m, all.length]);
        }
      }
    }

    setEmotions(items);
  };

  useEffect(() => {
    renderChart();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={css}>
      <Chart
        options={emotionsChart.options}
        series={emotionsChart.series}
        type="bubble"
        height="350"
      />
    </div>
  );
};

export default EmotionsBubleChart;
