const MoodEnum = {
  worse: 1,
  1: "Bad",
  bad: 2,
  2: "Not Good",
  neutral: 3,
  3: "Okay",
  good: 4,
  4: "Good",
  happy: 5,
  5: "Very Good"
};

export default MoodEnum;
