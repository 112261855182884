import React, { useState, useContext } from "react";
import { AuthContext } from "./Auth.js";
import { Link, withRouter, Redirect } from "react-router-dom";
import { Toast, Textarea } from "react-materialize";
import EmotionsEnum from "./emotionsEnum";
import Language, { getLanguageText } from "./Language";
import { ReactComponent as Sad } from "../media/sad.svg";
import { ReactComponent as Stressed } from "../media/stressed.svg";
import { ReactComponent as Pain } from "../media/pain.svg";
import { ReactComponent as Angry } from "../media/angry.svg";
import { ReactComponent as Tired } from "../media/tired.svg";
import { ReactComponent as Relaxed } from "../media/relaxed.svg";
import { ReactComponent as Depressed } from "../media/depressed.svg";
import { ReactComponent as InLove } from "../media/inLove.svg";
import { ReactComponent as Scared } from "../media/scared.svg";
import { ReactComponent as Nervous } from "../media/nervous.svg";
import { ReactComponent as Happy } from "../media/happy.svg";
import { ReactComponent as AteTooMuch } from "../media/ateTooMuch.svg";
import MaxReached, { maxIsReached } from "./MaxReached.js";
import Logo from "../media/moodypoo.png";

const AddDetails = props => {
  const { addDetails, location } = props;
  const [freeText, setFreeText] = useState("");
  const { poo, setPoo, language } = useContext(AuthContext);
  const [modal, setModal] = useState(false);
  const [details, setDetails] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [errorGeo, setErrorGeo] = useState(null);
  const [disable, setDisable] = useState("");

  //handle Text change freetext
  const handleChange = event => {
    setFreeText({ value: event.target.value });
  };

  //choose one or more emotions handler
  const imageClick = id => {
    if (details.includes(id)) {
      setDetails(details.filter(res => res !== id));
    } else {
      setDetails([...details, id]);
    }
  };

  //promise geolocation suucces submit
  const onChangeGeo = ({ coords, timestamp }) => {
    const loc = {
      latitude: coords.latitude,
      longitude: coords.longitude,
      accuracy: coords.accuracy,
      timestamp
    };
    submitActions(loc);
  };

  //promise geolocation error submit
  const onErrorGeo = error => {
    setErrorGeo(error.message);
    submitActions();
  };

  //get location data return promise onChangeGeo
  const getLocation = () => {
    const defaultSettings = {
      enableHighAccuracy: false,
      timeout: Infinity,
      maximumAge: 0
    };

    const geo = navigator.geolocation;
    if (!geo) {
      setErrorGeo("Geolocation is not supported");
      return;
    }
    geo.getCurrentPosition(onChangeGeo, onErrorGeo, defaultSettings);
  };

  const onSubmit = e => {
    e.preventDefault();

    setDisable("disabled");
    //let getLocation handle the submit through onchangeGeo promise
    getLocation();

    //submit without geoloaction
    if (errorGeo) {
      submitActions();
    }
  };

  //submit actions
  const submitActions = loc => {
    const dataObject = {
      ...poo,
      moodId: location.state.moodId,
      userId: location.state.userId,
      details: {
        freeText: freeText,
        emotions: details
      }
    };

    //if only mood remove shape and color from data object
    if (location.state.type === "mood") {
      delete dataObject.shapeId;
      delete dataObject.colorId;
    }

    if (loc) {
      dataObject.location = loc;
    }
    if (maxIsReached) {
      setModal(true);
    } else {
      addDetails(dataObject);

      setDisable("");
      setPoo(null);
      props.history.push("/");
    }
  };

  if (redirect) {
    return <Redirect to="/" />;
  }

  //if direct route redirect to home
  if (!location.id) {
    setRedirect(true);
  }

  //title of page
  const getMoodText = () => {
    const moodText = language === "nl" ? "Ik voel me... " : "Feeling... ";
    return moodText;
  };

  return (
    <React.Fragment>
      <div className="linkContainer">
        <Link to="/" onClick={() => setPoo(null)}>
          <i className="material-icons big">chevron_left</i>
        </Link>
        <h6>{getMoodText()} </h6>
        <div>
          <img id="logo" alt="moodypoo" src={Logo} style={{ width: 30 }} />
        </div>
      </div>
      <div className="container">
        <div id="detailIcons">
          <div className="detailsIcons_item">
            <Angry
              viewBox="0 0 90 90"
              onClick={() => imageClick(EmotionsEnum.angry)}
              fill={details.includes(EmotionsEnum.angry) ? "red" : ""}
            />
            <Language topic="emotions" id={EmotionsEnum.angry} />
          </div>
          <div className="detailsIcons_item">
            <Stressed
              viewBox="0 0 90 90"
              onClick={() => imageClick(EmotionsEnum.stressed)}
              fill={details.includes(EmotionsEnum.stressed) ? "red" : ""}
              className={
                details.includes(EmotionsEnum.stressed)
                  ? "detailIcons--red"
                  : ""
              }
            />
            <Language topic="emotions" id={EmotionsEnum.stressed} />
          </div>
          <div className="detailsIcons_item">
            <Pain
              viewBox="0 0 90 90"
              onClick={() => imageClick(EmotionsEnum.pain)}
              fill={details.includes(EmotionsEnum.pain) ? "red" : ""}
              className={
                details.includes(EmotionsEnum.pain) ? "detailIcons--red" : ""
              }
            />
            <Language topic="emotions" id={EmotionsEnum.pain} />
          </div>
          <div className="detailsIcons_item">
            <Sad
              viewBox="0 0 90 90"
              onClick={() => imageClick(EmotionsEnum.sad)}
              fill={details.includes(EmotionsEnum.sad) ? "red" : ""}
            />
            <Language topic="emotions" id={EmotionsEnum.sad} />
          </div>
          <div className="detailsIcons_item">
            <Scared
              viewBox="0 0 90 90"
              onClick={() => imageClick(EmotionsEnum.scared)}
              fill={details.includes(EmotionsEnum.scared) ? "red" : ""}
            />
            <Language topic="emotions" id={EmotionsEnum.scared} />
          </div>
          <div className="detailsIcons_item">
            <Nervous
              viewBox="0 0 90 90"
              onClick={() => imageClick(EmotionsEnum.nervous)}
              fill={details.includes(EmotionsEnum.nervous) ? "red" : ""}
            />
            <Language topic="emotions" id={EmotionsEnum.nervous} />
          </div>
          <div className="detailsIcons_item">
            <Depressed
              viewBox="0 0 90 90"
              onClick={() => imageClick(EmotionsEnum.depressed)}
              fill={details.includes(EmotionsEnum.depressed) ? "red" : ""}
            />
            <Language topic="emotions" id={EmotionsEnum.depressed} />
          </div>
          <div className="detailsIcons_item">
            <Tired
              viewBox="0 0 90 90"
              onClick={() => imageClick(EmotionsEnum.tired)}
              fill={details.includes(EmotionsEnum.tired) ? "red" : ""}
            />
            <Language topic="emotions" id={EmotionsEnum.tired} />
          </div>
          <div className="detailsIcons_item">
            <Relaxed
              viewBox="0 0 90 90"
              onClick={() => imageClick(EmotionsEnum.relaxed)}
              fill={details.includes(EmotionsEnum.relaxed) ? "red" : ""}
            />
            <Language topic="emotions" id={EmotionsEnum.relaxed} />
          </div>
          <div className="detailsIcons_item">
            <Happy
              viewBox="0 0 90 90"
              onClick={() => imageClick(EmotionsEnum.happy)}
              fill={details.includes(EmotionsEnum.happy) ? "red" : ""}
            />
            <Language topic="emotions" id={EmotionsEnum.happy} />
          </div>
          <div className="detailsIcons_item">
            <InLove
              viewBox="0 0 90 90"
              onClick={() => imageClick(EmotionsEnum.inLove)}
              fill={details.includes(EmotionsEnum.inLove) ? "red" : ""}
            />
            <Language topic="emotions" id={EmotionsEnum.inLove} />
          </div>
          <div className="detailsIcons_item">
            <AteTooMuch
              viewBox="0 0 90 90"
              onClick={() => imageClick(EmotionsEnum.ateTooMuch)}
              fill={details.includes(EmotionsEnum.ateTooMuch) ? "red" : ""}
            />
            <Language topic="emotions" id={EmotionsEnum.ateTooMuch} />
          </div>
        </div>
        <form action="post" onSubmit={onSubmit}>
          <Textarea
            maxLength="120"
            data-length={120}
            label={getLanguageText("ui", "DetailsFreeText", language)}
            value={freeText.value}
            onChange={handleChange}
          />
          {(details && details.length) > 0 ||
          (freeText && freeText.value.length > 0) ? (
            <Toast
              type="submit"
              className={`waves-effect waves-light ${disable}`}
              options={{
                html: getLanguageText("ui", "DetailsSubmitted", language)
              }}
            >
              {getLanguageText("ui", "DetailsSubmit", language)}
            </Toast>
          ) : (
            ""
          )}
        </form>
        <MaxReached show={modal} setModal={setModal} />
      </div>
    </React.Fragment>
  );
};

export default withRouter(AddDetails);
