import React, { useCallback, useContext } from "react";
import { withRouter, Redirect } from "react-router-dom";
import app from "../base";
import { AuthContext } from "./Auth.js";
import firebase from "firebase/app";
import Background from "../media/moodypoostart.svg";
import Google from "../media/btn_google_signin_light_normal_web.png";
import Twitter from "../media/signintwitter.png";

const Login = ({ history }) => {
  const handleLogin = useCallback(
    async event => {
      event.preventDefault();
      const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
      try {
        await app.auth().signInWithPopup(googleAuthProvider);
        history.push("/");
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  const handleLoginTwitter = useCallback(
    async event => {
      event.preventDefault();
      const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
      try {
        await app.auth().signInWithPopup(twitterAuthProvider);
        history.push("/");
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  const { user } = useContext(AuthContext);

  if (user) {
    return <Redirect to="/" />;
  }

  return (
    <div className="container">
      <h2 className="center-align">Moody Poo</h2>
      <div style={getStyle()}>
        <div className="signInWith">
          <img
            src={Google}
            style={{ marginTop: 150, width: 180 }}
            alt="sign in with Google"
            onClick={handleLogin}
          />
          <img
            src={Twitter}
            style={{ marginTop: 0, width: 180, display: "block" }}
            alt="sign in with Twitter"
            onClick={handleLoginTwitter}
          />
        </div>
      </div>
      <p>
        Welcome to MoodyPoo.io <br />
        Here you can track your bowel movement and your mood. <br />
        There is no need for you to register. You can login using your Google or
        Twitter account. MoodyPoo does not and will never store your email
        address or name in its database and will not use, store or share your
        Google or Twitter account information but will only use these for
        authentication purposes.
        <br />
        MoodyPoo will store your location data when you begin tracking your mood
        or bowel movement. Also with this location data additional weather data
        is collected. This data might be related to your mood or bowel movement.
        If you don't want MoodyPoo to track your location simply deny location
        tracking when asked.
      </p>
    </div>
  );
};

const getStyle = () => {
  return {
    backgroundImage: `url(${Background})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    maxHeight: 420,
    height: 420,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "contain"
  };
};

export default withRouter(Login);
