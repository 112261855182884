import React from "react";
import { ReactComponent as ShapeNuts } from "../media/shapeNuts.svg";
import { ReactComponent as ShapeLumpy } from "../media/shapeLumpy.svg";
import { ReactComponent as ShapeCracky } from "../media/shapeCracky.svg";
import { ReactComponent as ShapeSausage } from "../media/shapeSausage.svg";
import { ReactComponent as ShapeBlobs } from "../media/shapeBlobs.svg";
import { ReactComponent as ShapeFluffy } from "../media/shapeFluffy.svg";
import { ReactComponent as ShapeWatery } from "../media/shapeWatery.svg";
import { ReactComponent as ColorIcon } from "../media/colorIcon.svg";
import Language from "./Language";
import shapesEnum from "./shapesEnum";
import colorsEnum from "./colorsEnum";
import { withRouter } from "react-router-dom";
import Logo from "../media/moodypoo.png";

const Info = props => {
  const onBackHandler = () => {
    switch (props.location.id) {
      case "addpoo":
        props.history.push("/addpoo");
        break;
      case "pooresults":
        props.history.push("/results");
        break;
      default:
        props.history.push("/");
        break;
    }
  };

  return (
    <div id="info">
      <div className="linkContainer">
        {/* eslint-disable-next-line  */}
        <a onClick={onBackHandler}>
          <i className="material-icons big">chevron_left</i>
        </a>
        <h6>Info</h6>
        <div>
          <img id="logo" alt="moodypoo" src={Logo} style={{ width: 30 }} />
        </div>
      </div>

      <div className="infoGrid container">
        <span> </span>
        <h6>
          <Language topic="ui" id="infoTextureTitle" />{" "}
        </h6>
        <ShapeNuts />
        <Language topic="shape" id={shapesEnum.nuts} />
        <ShapeLumpy />
        <Language topic="shape" id={shapesEnum.lumpy} />
        <ShapeCracky />
        <Language topic="shape" id={shapesEnum.cracky} />
        <ShapeSausage />
        <Language topic="shape" id={shapesEnum.sausage} />
        <ShapeBlobs />
        <Language topic="shape" id={shapesEnum.blobs} />
        <ShapeFluffy />
        <Language topic="shape" id={shapesEnum.fluffy} />
        <ShapeWatery />
        <Language topic="shape" id={shapesEnum.watery} />
        <span> </span>
        <h6>
          <Language topic="ui" id="InfoColorTitle" />
        </h6>
        <ColorIcon fill={colorsEnum.brown} title="info brown poo color" />
        <Language topic="color" id={colorsEnum.brown} />
        <ColorIcon fill={colorsEnum.orange} title="info orange poo color" />
        <Language topic="color" id={colorsEnum.orange} />
        <ColorIcon fill={colorsEnum.green} title="info green poo color" />
        <Language topic="color" id={colorsEnum.green} />
        <ColorIcon fill={colorsEnum.yellow} title="info yellow poo color" />
        <Language topic="color" id={colorsEnum.yellow} />
        <ColorIcon
          fill={colorsEnum.darkbrown}
          title="info darkbrown poo color"
        />
        <Language topic="color" id={colorsEnum.darkbrown} />
        <ColorIcon fill={colorsEnum.white} title="info white poo color" />
        <Language topic="color" id={colorsEnum.white} />
        <ColorIcon fill={colorsEnum.red} title="info red poo color" />
        <Language topic="color" id={colorsEnum.red} />
      </div>
    </div>
  );
};

export default withRouter(Info);
