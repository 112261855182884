import React, { useContext } from "react";
import { AuthContext } from "./Auth.js";
import { renderMoodyPoo, renderDates } from "./Results";

const PooResultsLastSeven = () => {
  const { data } = useContext(AuthContext);

  const filtered =
    data &&
    data.filter(res => {
      return res.shapeId;
    });

  if (!filtered) return "";
  return (
    <div id="poo_results_seven">
      <span>Last 7</span>
      <div className="poo_result_grid">
        {renderMoodyPoo(filtered, 0, 7, true)}
      </div>
      <div className="poo_result_grid_labels">
        {renderDates(filtered, 0, 7, true)}
      </div>
    </div>
  );
};

export default PooResultsLastSeven;
