import React, { useContext } from "react";
import { CSVLink } from "react-csv";
import { AuthContext } from "./Auth.js";
import shapesEnum from "./shapesEnum";
import { RenderDetailsEmosArray } from "./DetailsInfo";
import { getDate } from "./Utils";
import { getLanguageText } from "./Language";
import colorsEnum from "./colorsEnum.js";

const Report = () => {
  const { data, language } = useContext(AuthContext);

  const filtered =
    data &&
    data.filter(res => {
      return res.shapeId;
    });

  const dataset =
    filtered &&
    filtered.map(rec => {
      const returnObject = {
        Name: shapesEnum[rec.shapeId],
        Description: getLanguageText("shape", rec.shapeId, language),
        Color: getLanguageText(
          "colors",
          colorsEnum[rec.colorId.toLowerCase()],
          language
        ),
        Mood: getLanguageText("mood", rec.moodId, language),
        DateTime: getDate(rec.createdAt)
      };
      if (rec.details)
        returnObject.Reasons = RenderDetailsEmosArray(rec, true, language);
      return returnObject;
    });

  const headers = [
    { label: getLanguageText("ui", "DateTime", language), key: "DateTime" },
    { label: getLanguageText("ui", "BowelMovement", language), key: "Name" },
    {
      label: getLanguageText("ui", "Description", language),
      key: "Description"
    },
    { label: getLanguageText("ui", "PooColor", language), key: "Color" },
    { label: getLanguageText("ui", "Mood", language), key: "Mood" },
    { label: getLanguageText("ui", "Reasons", language), key: "Reasons" }
  ];

  return dataset ? (
    <CSVLink filename={"MoodyPoo.csv"} data={dataset} headers={headers}>
      MoodyPoo.csv
    </CSVLink>
  ) : (
    "loading..."
  );
};

export default Report;
