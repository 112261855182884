const shapesEnum = {
  nuts: 1,
  1: "Nuts",
  lumpy: 2,
  2: "Lumpy",
  cracky: 3,
  3: "Cracky",
  sausage: 4,
  4: "Sausage",
  blobs: 5,
  5: "Blobs",
  fluffy: 6,
  6: "Fluffy",
  watery: 7,
  7: "Watery"
};

export default shapesEnum;
