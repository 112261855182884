import React, { useContext } from "react";
import { AuthContext } from "./Auth.js";
import shapesEnum from "./shapesEnum";
import colorsEnum from "./colorsEnum";
import emotionsEnum from "./emotionsEnum";
import MoodEnum from "./moodEnum.js";
import PropTypes from "prop-types";

const Texts = [
  {
    topic: "ui",
    keys: [
      {
        key: "NavResults",
        value: {
          en: "Results",
          nl: "Resultaten"
        }
      },
      {
        key: "DateTime",
        value: {
          en: "DateTime",
          nl: "DatumTijd"
        }
      },
      {
        key: "Mood",
        value: {
          en: "Mood",
          nl: "Je voelt je"
        }
      },
      {
        key: "Mood2",
        value: {
          en: "Mood",
          nl: "Stemming"
        }
      },
      {
        key: "MoodDetails",
        value: {
          en: "Mood Details",
          nl: "Stemming Details"
        }
      },
      {
        key: "Feeling",
        value: {
          en: "Feeling",
          nl: "Je voelt je"
        }
      },
      {
        key: "and",
        value: {
          en: " and ",
          nl: " en "
        }
      },
      {
        key: "on",
        value: {
          en: "on",
          nl: "op"
        }
      },
      {
        key: "Reasons",
        value: {
          en: "Reasons",
          nl: "Redenen"
        }
      },
      {
        key: "DashBoardTitle",
        value: {
          en: "Dashboard",
          nl: "Dashboard"
        }
      },
      {
        key: "BowelMovement",
        value: {
          en: "Bowel Movement",
          nl: "Stoelgang"
        }
      },
      {
        key: "Description",
        value: {
          en: "Description",
          nl: "Omschrijving"
        }
      },
      {
        key: "PooColor",
        value: {
          en: "Poop Color",
          nl: "Poep Kleur"
        }
      },
      {
        key: "PooShape",
        value: {
          en: "Poop Shape",
          nl: "Poep Vorm"
        }
      },
      {
        key: "NavSettings",
        value: {
          en: "Settings",
          nl: "Instellingen"
        }
      },
      {
        key: "NavSignOut",
        value: {
          en: "Sign Out",
          nl: "Uitloggen"
        }
      },
      {
        key: "NavSigIn",
        value: {
          en: "Sign in",
          nl: "Inloggen"
        }
      },
      {
        key: "SettingsDownloadReport",
        value: {
          en: "Download Bowel Movement Report",
          nl: "Download Stoelgang rapport"
        }
      },
      {
        key: "SettingsLanguage",
        value: {
          en: "Language",
          nl: "Taal"
        }
      },
      {
        key: "SettingsNewVersion",
        value: {
          en: "New Version",
          nl: "Nieuwe versie"
        }
      },
      {
        key: "SettingsNewVersionCheck",
        value: {
          en: "Check for new version",
          nl: "Controleer hier"
        }
      },
      {
        key: "infoTextureTitle",
        value: { en: "Different Poo Textures", nl: "Soorten stoelgang" }
      },
      {
        key: "InfoColorTitle",
        value: {
          en: "Poo Colors",
          nl: "Poep kleuren"
        }
      },
      {
        key: "DetailsFreeText",
        value: {
          en: "Add details free text here...",
          nl: "Noteer aanvullende informatie..."
        }
      },
      {
        key: "DetailsSubmitted",
        value: {
          en: "Details submitted",
          nl: "Details toegevoegd"
        }
      },
      {
        key: "DetailsSubmit",
        value: {
          en: "submit details",
          nl: "details toevoegen"
        }
      },
      {
        key: "AddPooTitle",
        value: {
          en: "Edit Poo ",
          nl: "Wijzig Poep "
        }
      },
      {
        key: "AddPooTitleAdd",
        value: {
          en: "Track your Poo",
          nl: "Registreer stoelgang"
        }
      },
      {
        key: "MoodTitleEdit",
        value: {
          en: "Edit your mood ",
          nl: "Wijzigen stemming"
        }
      },
      {
        key: "MoodTitle",
        value: {
          en: "How are you feeling?",
          nl: "Hoe voel je je?"
        }
      },
      {
        key: "MoodAddDetail",
        value: {
          en: "ADD DETAILS",
          nl: "MEER DETAILS "
        }
      },
      {
        key: "MoodSubmitMood",
        value: {
          en: "SUBMIT MOOD",
          nl: "OPSLAAN"
        }
      },
      {
        key: "MoodSubmitPoo",
        value: {
          en: "SUBMIT POO",
          nl: "OPSLAAN"
        }
      },
      {
        key: "MoodSubmittedPoo",
        value: {
          en: "Poo Submitted",
          nl: "Stoelgang geregistreerd"
        }
      },
      {
        key: "MoodSubmittedMood",
        value: {
          en: "Mood Submitted",
          nl: "Stemming geregistreerd"
        }
      },
      {
        key: "DetailsStoryMood",
        value: {
          en: "You are feeling ",
          nl: "Je voelt je "
        }
      },
      {
        key: "DetailsStoryDetailsMood",
        value: {
          en: "You feel ",
          nl: "Je voelt je "
        }
      },
      {
        key: "DetailsStoryDetailsFreeText",
        value: {
          en: " You also mentioned: ",
          nl: " Je noemde ook:  "
        }
      }
    ]
  },
  {
    topic: "emotions",
    keys: [
      {
        key: emotionsEnum.sad,
        value: {
          en: "Sad",
          nl: "Verdrietig"
        }
      },
      {
        key: emotionsEnum.stressed,
        value: {
          en: "Stressed",
          nl: "Gestresst"
        }
      },
      {
        key: emotionsEnum.pain,
        value: {
          en: "Pain",
          nl: "Pijn"
        }
      },
      {
        key: emotionsEnum.angry,
        value: {
          en: "Angry",
          nl: "Boos"
        }
      },
      {
        key: emotionsEnum.tired,
        value: {
          en: "Tired",
          nl: "Moe"
        }
      },
      {
        key: emotionsEnum.relaxed,
        value: {
          en: "Relaxed",
          nl: "Relaxed"
        }
      },
      {
        key: emotionsEnum.inLove,
        value: {
          en: "Excited",
          nl: "Uitgelaten"
        }
      },
      {
        key: emotionsEnum.depressed,
        value: {
          en: "Depressed",
          nl: "Depressief"
        }
      },
      {
        key: emotionsEnum.scared,
        value: {
          en: "Anxious",
          nl: "Angstig"
        }
      },
      {
        key: emotionsEnum.nervous,
        value: {
          en: "Nervous",
          nl: "Nerveus"
        }
      },
      {
        key: emotionsEnum.happy,
        value: {
          en: "Happy",
          nl: "Blij"
        }
      },
      {
        key: emotionsEnum.ateTooMuch,
        value: {
          en: "Bloated",
          nl: "Op-geblazen"
        }
      }
    ]
  },
  {
    topic: "mood",
    keys: [
      {
        key: MoodEnum.worse,
        value: {
          en: "bad",
          nl: "slecht"
        }
      },
      {
        key: MoodEnum.bad,
        value: {
          en: "not good",
          nl: "niet goed"
        }
      },
      {
        key: MoodEnum.neutral,
        value: {
          en: "okay",
          nl: "ok"
        }
      },
      {
        key: MoodEnum.good,
        value: {
          en: "good",
          nl: "goed"
        }
      },
      {
        key: MoodEnum.happy,
        value: {
          en: "happy",
          nl: "heel goed"
        }
      }
    ]
  },
  {
    topic: "shape",
    keys: [
      {
        key: shapesEnum.nuts,
        value: {
          en: "Seperate hard lumps, like nuts (Very constipated)",
          nl: "Losse harde keutels, zoals noten (moeilijk uit te scheiden)"
        }
      },
      {
        key: shapesEnum.lumpy,
        value: {
          en: "Lumpy and sausage like (Slightly constipated)",
          nl: "Als een worst, maar klonterig"
        }
      },
      {
        key: shapesEnum.cracky,
        value: {
          en:
            "A sausage like shape with cracks in the surface (Normal but you need more water)",
          nl: "Als een worst, maar met barstjes aan de buitenkant"
        }
      },
      {
        key: shapesEnum.sausage,
        value: {
          en: "Like a smooth, soft sausage or snake (Normal)",
          nl: "Als een worst of slang, glad en zacht"
        }
      },
      {
        key: shapesEnum.blobs,
        value: {
          en: "Soft blobs with clear-cut edges (Lacking fibre)",
          nl: "Zachte keutels met duidelijke randen (makkelijk uit te scheiden)"
        }
      },
      {
        key: shapesEnum.fluffy,
        value: {
          en:
            "Mushy consistency with ragged edges, a mushy stool (Inflammation)",
          nl: "Zachte stukjes met gehavende randen, een papperige uitscheiding"
        }
      },
      {
        key: shapesEnum.watery,
        value: {
          en:
            "Liquid consistency with no solid peaces (Inflammation and diarhea)",
          nl: "Waterig, geen vaste stukjes. Helemaal vloeibaar"
        }
      }
    ]
  },
  {
    topic: "shapeStory",
    keys: [
      {
        key: shapesEnum.nuts,
        value: {
          en: "Your poop is very hard and look loke nuts. You are constipated.",
          nl:
            "Je poep is hard en ziet eruit als noten. Je hebt last van verstopping."
        }
      },
      {
        key: shapesEnum.lumpy,
        value: {
          en: "You are slightly constipated.",
          nl: "Je hebt last van lichte verstopping. "
        }
      },
      {
        key: shapesEnum.cracky,
        value: {
          en: "Your bowel movement is normal but you need to drink more water.",
          nl: "Je stoelgang is normaal maar je hebt meer water nodig."
        }
      },
      {
        key: shapesEnum.sausage,
        value: {
          en: "Your bowel movement is normal.",
          nl: "Je stoelgang is normaal."
        }
      },
      {
        key: shapesEnum.blobs,
        value: {
          en: "Your poop is a bit too soft and you need more fibre.",
          nl:
            "Je poep bestaat uit zachte stukjes en is papperig. Eet meer vezels."
        }
      },
      {
        key: shapesEnum.fluffy,
        value: {
          en:
            "Your bowel movement is a mushy stool which could be a sign of mild diarrhea.",
          nl: "Je hebt last van lichte diarree."
        }
      },
      {
        key: shapesEnum.watery,
        value: {
          en: "You've got diarrhea.",
          nl: "Je hebt diarree."
        }
      }
    ]
  },
  {
    topic: "colors",
    keys: [
      {
        key: colorsEnum.brown,
        value: { en: "Brown", nl: "Bruin" }
      },
      {
        key: colorsEnum.darkbrown,
        value: { en: "Darkbrown", nl: "Donkerbruin" }
      },
      {
        key: colorsEnum.green,
        value: { en: "Green", nl: "Groen" }
      },
      {
        key: colorsEnum.orange,
        value: { en: "Orange", nl: "Oranje" }
      },
      {
        key: colorsEnum.red,
        value: { en: "Red", nl: "Rood" }
      },
      {
        key: colorsEnum.white,
        value: { en: "White", nl: "Wit" }
      },
      {
        key: colorsEnum.yellow,
        value: { en: "Yellow", nl: "Geel" }
      }
    ]
  },
  {
    topic: "color",
    keys: [
      {
        key: colorsEnum.brown,
        value: {
          en: "Normal",
          nl:
            "Normale poep hoort bruin te zijn. Afhankelijk van wat je eet kan het variëren van donkerbruin tot lichtbruin"
        }
      },
      {
        key: colorsEnum.orange,
        value: {
          en:
            "Consuming many orange-colored foods, which are rich in a pigment called beta-carotene, causes orange stool. Carrots, sweet potatoes, and winter squash are among the many foods that contain this pigment. However, blocked bile ducts or certain medications including some antacids and the antibiotic rifampin can cause orange poop.",
          nl:
            "Het consumeren van veel oranje voedsel, dat rijk is aan een pigment dat bètacaroteen wordt genoemd, veroorzaakt oranje ontlasting. Wortelen, zoete aardappelen en pompoen behoren tot de vele voedingsmiddelen die dit pigment bevatten. Geblokkeerde galwegen of bepaalde medicijnen, waaronder sommige maagzuurremmers en het antibioticum rifampin kunnen oranje poep veroorzaken."
        }
      },
      {
        key: colorsEnum.green,
        value: {
          en:
            "Spinach, kale, or other green foods can cause green poop. However, green-colored stool may be a sign that there is too much bile and not enough bilirubin in the poop",
          nl:
            "Eet je veel spinazie, andijvie of sla? Dan kan de kleur de volgende dag wat groenig zijn. Niets om je zorgen om te maken. Heb je geen groene bladgroenten gegeten? Houd dan de ontlasting in de gaten. Het kan wijzen op een maagdarminfectie of een voedselintolerantie."
        }
      },
      {
        key: colorsEnum.yellow,
        value: {
          en:
            "If stool appears yellow or is greasy-looking, it suggests the poop contains too much fat. This may be the result of absorption issues, or difficulty producing enzymes or bile.",
          nl:
            "Als de ontlasting geel lijkt of er vettig uitziet, suggereert dit dat de kak te veel vet bevat. Dit kan het gevolg zijn van absorptieproblemen of problemen bij het produceren van enzymen of gal."
        }
      },
      {
        key: colorsEnum.darkbrown,
        value: {
          en:
            "Stools that are black, especially if they have the appearance of coffee grounds, suggest gastrointestinal bleeding. Substances such as iron supplements, black licorice, black stout, and bismuth medications also cause black poop",
          nl:
            "Donkerbruin kan, maar echt zwarte poep? Dan moet er toch een alarmbelletje afgaan. Bij zwarte poep kan er sprake zijn van bloedverlies. De oorsprong ligt vaak al aan het begin van je spijsverteringskanaal, zoals de maag of dunne darm. In de darmen wordt dit bloed met de ontlasting vermengt en krijgt het een zwarte kleur.          Zwarte poep kan ook de donkere kleur krijgen door het gebruik van ijzerpreparaten of veel ijzerrijke voeding te eten, zoals spinazie. Ook mango, bosbessen en bepaalde soorten vlees (rund en lam) kunnen je poep zwart kleuren. Duurt het langer dan 3 dagen dat je ontlasting zwart is van kleur, ga dan even naar de huisarts voor onderzoek. Een mogelijke oorzaak kan een maagzweer zijn."
        }
      },
      {
        key: colorsEnum.white,
        value: {
          en:
            "If stools are white, gray, or pale, a person may have an issue with the liver or gallbladder as pale stools suggest a lack of bile. Some anti-diarrhea medications cause white stools",
          nl:
            "Dit is vaak geen goed teken. Het kan wijzen op een verstopping van de galwegen. Door galvloeistof kleurt je poep kruin. Zitten de galwegen verstopt, dan komt de vloeistof niet in de darmen terecht. Een mogelijke oorzaak kunnen galstenen zijn of een ontsteking van de galwegen. Ziet je ontlasting vaak bleek? Even naar de huisarts."
        }
      },
      {
        key: colorsEnum.red,
        value: {
          en:
            "Poop that is red-colored may be the result of gastrointestinal bleeding. Small amounts of blood in the stool can indicate hemorrhoids. Eating beets or red berries, or drinking beet or tomato juice, also turns poop red. Once these foods have passed through the digestive tract, poop should become brown again.",
          nl:
            "Als de ontlasting rood is, denk dan even na wat je hebt gegeten. Bietjes of rode kool kunnen poep een rode kleur geven. In dat geval is er niets aan de hand. In andere gevallen kan het gaan om bloed in de ontlasting. De meest onschuldige optie is dat het veroorzaakt wordt door aambeien. Soms kan bloed ook wijzen op een ontsteking in de darmen of een poliep. In ernstige gevallen duidt het op kanker. Hou het dus goed in de gaten en maak een afspraak met de huisarts als je het niet vertrouwt."
        }
      }
    ]
  }
];

export const getLanguageText = (topic, key, language = "en") => {
  const top = Texts.find(res => {
    return res.topic === topic;
  });
  const value = top.keys.find(res => {
    return res.key === key;
  });
  return value && value.value[language];
};

const Language = ({ topic, id }) => {
  const { language } = useContext(AuthContext);

  return <span>{getLanguageText(topic, id, language)}</span>;
};

Language.propTypes = {
  topic: PropTypes.string.isRequired,
  id: PropTypes.any.isRequired
};

export default Language;
