import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "./Auth.js";
import { ReactComponent as Worse } from "../media/worse.svg";
import { ReactComponent as Bad } from "../media/bad.svg";
import { ReactComponent as Neutral } from "../media/neutral.svg";
import { ReactComponent as Good } from "../media/good.svg";
import { ReactComponent as Happy } from "../media/happy.svg";
import { Toast } from "react-materialize";
import MoodEnum from "./moodEnum";
import { Link, Redirect, withRouter } from "react-router-dom";
import { getDate } from "./Utils";
import { getLanguageText } from "./Language.js";
import { db } from "../base";
import Logo from "../media/moodypoo.png";

const Mood = props => {
  const { addMood, changeMood, type, editMood } = props;
  const { user, poo, setPoo, language } = useContext(AuthContext);
  const [editMode, setEditMode] = useState(false);
  const [state, setState] = useState({
    mood: 0
  });
  const [redirect, setRedirect] = useState(false);
  const [errorGeo, setErrorGeo] = useState(null);
  const [disable, setDisable] = useState("");

  const addErrorToDb = errorMessage => {
    const userUid = user ? user.uid : null;
    const error = {
      error: errorMessage,
      userId: userUid
    };
    db.collection("Errors")
      .add({
        ...error,
        createdAt: new Date()
      })
      .then(() => {
        console.log("log created");
      })
      .catch(err => {
        console.log("error", err);
      });
  };

  //promise geolocation suucces submit
  const onChangeGeo = ({ coords, timestamp }) => {
    const loc = {
      latitude: coords.latitude,
      longitude: coords.longitude,
      accuracy: coords.accuracy,
      timestamp
    };
    handleSubmitData(loc);
  };

  //promise geolocation error submit
  const onErrorGeo = error => {
    if (error) {
      setErrorGeo(error.message);
      addErrorToDb(error.message);
    } else addErrorToDb("error but empty message");
    handleSubmitData();
  };

  const getLocation = () => {
    const defaultSettings = {
      enableHighAccuracy: false,
      timeout: Infinity,
      maximumAge: 0
    };

    const geo = navigator.geolocation;
    if (!geo) {
      setErrorGeo("Geolocation is not supported");
      return;
    }
    geo.getCurrentPosition(onChangeGeo, onErrorGeo, defaultSettings);
  };

  //change mood on mood icon click
  const imageClick = id => {
    setState({
      mood: id
    });

    changeMood && changeMood(id);
  };

  const onSubmit = e => {
    e.preventDefault();
    setDisable("disabled");
    //get geo location wich will handle the rest of the submit in the onChangeGeo promise
    getLocation();

    //submit without geoloaction
    if (errorGeo) {
      addErrorToDb(errorGeo);
      handleSubmitData();
    }
  };

  const handleSubmitData = loc => {
    const userUid = user ? user.uid : null;

    const moodObject = {
      moodId: state.mood,
      userId: userUid
    };

    if (loc) {
      moodObject.location = loc;
    }

    const dataObject = {
      ...poo,
      ...moodObject
    };

    if (editMode) {
      editMood(dataObject);
      setRedirect(true);
      setPoo(null);
    } else {
      addMood(dataObject);
    }

    setState({
      mood: 0
    });

    setDisable("");
  };

  //Text on the Toast, Mood or Poo submitted
  const htmlText = () => {
    if (type === "mood")
      return { html: getLanguageText("ui", "MoodSubmittedMood", language) };
    else return { html: getLanguageText("ui", "MoodSubmittedPoo", language) };
  };

  //Submit button text, Poo or Mood
  const displayButtonText = () => {
    return type === "mood"
      ? getLanguageText("ui", "MoodSubmitMood", language)
      : getLanguageText("ui", "MoodSubmitPoo", language);
  };

  //render submit button and Link button to AddDetails
  const submitButton = (
    <div className="buttonsSubmit">
      <form action="post" onSubmit={onSubmit}>
        <Toast
          type="submit"
          className={`waves-effect waves-light ${disable}`}
          options={htmlText()}
        >
          {displayButtonText()}
        </Toast>
      </form>
      <Link
        className={`btn waves-effect waves-light ${disable}`}
        to={{
          pathname: "/adddetails",
          id: "mood",
          state: {
            type: type,
            moodId: state.mood,
            userId: user ? user.uid : null
          }
        }}
      >
        {getLanguageText("ui", "MoodAddDetail", language)}
      </Link>
    </div>
  );

  //check for Edit mode = when poo object had key
  useEffect(() => {
    //if edit set mood
    if (poo && poo.key && !poo.shapeId) {
      setEditMode(true);
      setState({ mood: poo.moodId });
    }
    // eslint-disable-next-line
  }, []);

  if (redirect) {
    return <Redirect to="/" />;
  }

  //if route from editmood and no location id redirect to home
  if (props.location.pathname === "/editmood" && !props.location.id) {
    setRedirect(true);
  }

  const renderBacklink = () => {
    return (
      <div className="linkContainer">
        <Link to="/results" onClick={() => setPoo(null)}>
          <i className="material-icons big">chevron_left</i>
        </Link>
        <div></div>
        <div>
          <img id="logo" alt="moodypoo" src={Logo} style={{ width: 30 }} />
        </div>
      </div>
    );
  };

  const renderTitle = () => {
    return editMode && poo
      ? `${getLanguageText("ui", "MoodTitleEdit", language)} ${getDate(
          poo.createdAt
        )}`
      : getLanguageText("ui", "MoodTitle", language);
  };

  return (
    <div className="moodContainer">
      {editMood ? renderBacklink() : null}
      <div className={editMode ? "container" : ""}>
        <h6 className="center-align">{renderTitle()}</h6>
        <div className="mood_grid">
          <Worse
            viewBox="0 0 90 90"
            onClick={() => imageClick(MoodEnum.worse)}
            fill={state.mood === MoodEnum.worse ? "red" : ""}
          />
          <Bad
            viewBox="0 0 90 90"
            onClick={() => imageClick(MoodEnum.bad)}
            fill={state.mood === MoodEnum.bad ? "red" : ""}
          />
          <Neutral
            viewBox="0 0 90 90"
            onClick={() => imageClick(MoodEnum.neutral)}
            fill={state.mood === MoodEnum.neutral ? "red" : ""}
          />
          <Good
            viewBox="0 0 90 90"
            onClick={() => imageClick(MoodEnum.good)}
            fill={state.mood === MoodEnum.good ? "red" : ""}
          />
          <Happy
            viewBox="0 0 90 90"
            onClick={() => imageClick(MoodEnum.happy)}
            fill={state.mood === MoodEnum.happy ? "red" : ""}
          />
        </div>
        {state.mood !== 0 ? submitButton : ""}
      </div>
    </div>
  );
};

export default withRouter(Mood);
