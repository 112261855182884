const colorsEnum = {
  brown: "#74431B",
  "#74431B": "Brown",
  1: "Brown",
  orange: "#F89329",
  "#F89329": "Orange",
  2: "Orange",
  green: "#879C5B",
  "#879C5B": "Green",
  3: "Green",
  yellow: "#E7C935",
  "#E7C935": "Yellow",
  4: "Yellow",
  darkbrown: "#391600",
  "#391600": "Darkbrown",
  5: "Darkbrown",
  white: "#E9E5E2",
  "#E9E5E2": "White",
  6: "White",
  red: "#AC4733",
  "#AC4733": "Red",
  7: "Red"
};

export default colorsEnum;
