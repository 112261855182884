import React, { useState, useContext, useEffect } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { AuthContext } from "./Auth.js";
import Locations from "./Locations";
import ApiKeys from "../ApiKeys";
import MoodyPoo from "./MoodyPoo";
import colorsEnum from "./colorsEnum";
import { getDate, months } from "./Utils";
import { getLanguageText } from "./Language";
import { RenderDetailsEmosArray } from "./DetailsInfo";
import Logo from "../media/moodypoo.png";

const Details = props => {
  const { id } = props.match.params;
  const [redirect, setRedirect] = useState(false);
  const { poo, setPoo, language } = useContext(AuthContext);

  useEffect(() => {
    //if no id from url param
    if (!id) {
      setRedirect(true);
    }
    //if not comming from results page
    if (props.location && !props.location.id) {
      setRedirect(true);
    }
    // eslint-disable-next-line
  }, []);

  if (redirect) {
    setPoo(null);
    return <Redirect to="/results" />;
  }

  if (!poo) return "loading";

  const location = poo && poo.location;

  const getWeatherIcon = () => {
    const weather = poo && poo.weather;
    const icon =
      weather &&
      weather.weather &&
      weather.weather[0] &&
      weather.weather[0].icon;

    const url = icon ? `${ApiKeys.owmIconUrl}${icon}@2x.png` : null;

    return url ? <img alt="weather" src={url} /> : null;
  };

  const getTemperature = () => {
    const temp =
      poo && poo.weather && poo.weather.main && poo.weather.main.temp;
    return temp ? <div className="details--weather-temp">{temp} °C</div> : null;
  };

  const makeStory = () => {
    if (!poo) return;
    let story = "";
    const dat = poo.createdAt.toDate();
    const day = dat.getDate();
    const month = months[dat.getMonth()];
    const youWereFeeling = `${getLanguageText(
      "ui",
      "DetailsStoryMood",
      language
    )} ${getLanguageText("mood", poo.moodId, language)} ${getLanguageText(
      "ui",
      "on",
      language
    )} `;
    const feeling = `${youWereFeeling} ${month} ${day}.`;
    const detailsEmosArray = RenderDetailsEmosArray(poo, true, language);
    const feelingDetails = detailsEmosArray
      ? ` ${getLanguageText(
          "ui",
          "DetailsStoryDetailsMood",
          language
        )} ${RenderDetailsEmosArray(poo, true, language)}.`
      : "";
    const feelingDetailsFree =
      poo.details && poo.details.freeText && poo.details.freeText.value
        ? `${getLanguageText("ui", "DetailsStoryDetailsFreeText", language)} ${
            poo.details.freeText.value
          }`
        : "";
    const poopText = poo.shapeId
      ? getLanguageText("shapeStory", poo.shapeId, language)
      : null;

    story = story + feeling + feelingDetails + feelingDetailsFree;

    return (
      <p>
        {story}
        <br />
        {poopText}
      </p>
    );
  };

  return (
    <div className="details">
      <div className="linkContainer">
        <Link to="/results" onClick={() => setPoo(null)}>
          <i className="material-icons big">chevron_left</i>
        </Link>
        <h6>Details</h6>
        <div>
          {" "}
          <img id="logo" alt="moodypoo" src={Logo} style={{ width: 30 }} />
        </div>
      </div>
      {poo && location ? (
        <Locations
          latitude={location.latitude}
          longitude={location.longitude}
          pooObject={poo}
        />
      ) : (
        ""
      )}
      <div className="container">
        <div className="details--header">
          <MoodyPoo
            mood={{ id: poo.moodId }}
            shape={{ id: poo.shapeId ? poo.shapeId : 0 }}
            color={{
              id: poo.colorId ? colorsEnum[poo.colorId.toLowerCase()] : "#fff"
            }}
          />
          <div className="details--header-title">
            <div className="details--header-title--text">
              {poo.shapeId ? "POO" : "MOOD"}
            </div>
            <div className="detials--header-title--date">{`${getDate(
              poo.createdAt
            )}`}</div>
          </div>
          <div className="details--weather">
            {getWeatherIcon()}
            {getTemperature()}
          </div>
        </div>
        <div className="story">{makeStory()}</div>
      </div>
    </div>
  );
};

export default withRouter(Details);
