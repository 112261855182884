const pad = (num, size) => {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
};

export const months = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec"
];

export const getDate = createdAt => {
  const dat = createdAt.toDate();
  const dateFormat =
    dat.getFullYear() + "-" + (dat.getMonth() + 1) + "-" + dat.getDate();
  const time = dat.getHours() + ":" + pad(dat.getMinutes(), 2);
  return dateFormat + " " + time;
};
