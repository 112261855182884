import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "./Auth.js";
import { ReactComponent as ColorIcon } from "../media/colorIcon.svg";
import { ReactComponent as ShapeNuts } from "../media/shapeNuts.svg";
import { ReactComponent as ShapeLumpy } from "../media/shapeLumpy.svg";
import { ReactComponent as ShapeCracky } from "../media/shapeCracky.svg";
import { ReactComponent as ShapeSausage } from "../media/shapeSausage.svg";
import { ReactComponent as ShapeBlobs } from "../media/shapeBlobs.svg";
import { ReactComponent as ShapeFluffy } from "../media/shapeFluffy.svg";
import { ReactComponent as ShapeWatery } from "../media/shapeWatery.svg";
import colorsEnum from "./colorsEnum";
import shapesEnum from "./shapesEnum";
import moodEnum from "./moodEnum";
import Mood from "./Mood";
import { Link, withRouter, Redirect } from "react-router-dom";
import MoodyPoo from "./MoodyPoo";
import PooResultsLastSeven from "./PooResultsLastSeven";
import { getDate } from "./Utils";
import MaxReached, { maxIsReached } from "./MaxReached.js";
import Language, { getLanguageText } from "./Language";
import Logo from "../media/moodypoo.png";

const AddPoo = props => {
  const { addPoo } = props;
  const { id } = props.match.params;
  const [shape, setShape] = useState({
    id: shapesEnum.sausage
  });
  const [color, setColor] = useState({
    id: colorsEnum.brown
  });
  const [mood, setMood] = useState({
    id: moodEnum.neutral
  });
  const [modal, setModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const { poo, setPoo, data, language } = useContext(AuthContext);

  const onBackHandler = () => {
    switch (props.location.id) {
      case "poostart":
        props.history.push("/");
        break;
      case "results":
        props.history.push("/results");
        break;
      default:
        props.history.push("/");
        break;
    }
  };

  const shapeChangeHandler = id => {
    setShape({
      id: id
    });
    setPoo({ ...poo, shapeId: id });
  };
  const colorHandler = id => {
    setColor({
      id: id
    });
    setPoo({ ...poo, colorId: colorsEnum[id].toLowerCase() });
  };

  const changeMoodHandler = moodId => {
    setMood({ id: moodId });
  };

  const getFill = id => {
    return shape.id === id ? "red" : "";
  };

  const getStroke = id => {
    return color.id === id ? "red" : "";
  };

  const addPooHandler = dataObject => {
    if (maxIsReached) {
      setModal(true);
    } else {
      //edit mode
      if (editMode) {
        dataObject.key = poo.key;
      }
      //combine Mood and Shape and Color
      dataObject.shapeId = shape.id;
      dataObject.colorId = colorsEnum[color.id];

      addPoo(dataObject);
      props.history.push("/");
    }
  };

  const renderTitle = () => {
    return editMode && poo
      ? getLanguageText("ui", "AddPooTitle", language) + getDate(poo.createdAt)
      : getLanguageText("ui", "AddPooTitleAdd", language);
  };

  useEffect(() => {
    //if edit set shape color and mood
    if (data) {
      const record = data.filter(rec => {
        return rec.key === id;
      });
      if (record && record[0]) {
        const editRecord = record[0];
        setPoo(editRecord);
        shapeChangeHandler(editRecord.shapeId);
        colorHandler(colorsEnum[editRecord.colorId.toLowerCase()]);
        changeMoodHandler(editRecord.moodId);
        setEditMode(true);
      } else {
        setPoo({
          ...poo,
          shapeId: shape.id,
          colorId: colorsEnum[color.id].toLowerCase()
        });
      }
    }
    // eslint-disable-next-line
  }, [data]);

  if (redirect) {
    return <Redirect to="/" />;
  }

  if (!props.location.id) {
    setRedirect(true);
  }

  return (
    <React.Fragment>
      <div className="linkContainer">
        {/* eslint-disable-next-line  */}
        <a onClick={onBackHandler}>
          <i className="material-icons big">chevron_left</i>
        </a>
        <h6>{renderTitle()}</h6>
        {!editMode ? (
          <Link
            to={{
              pathname: "/info",
              id: "addpoo"
            }}
          >
            <i className="material-icons big">info_outline</i>
          </Link>
        ) : (
          <div>
            <img id="logo" alt="moodypoo" src={Logo} style={{ width: 30 }} />
          </div>
        )}
      </div>
      <div className="container">
        <div className="addpoo ">
          <div id="addpoo_colors">
            <ColorIcon
              fill={colorsEnum.brown}
              title="choose brown poo color"
              stroke={getStroke(colorsEnum.brown)}
              onClick={() => colorHandler(colorsEnum.brown)}
            />
            <ColorIcon
              fill={colorsEnum.orange}
              title="choose orange poo color"
              stroke={getStroke(colorsEnum.orange)}
              onClick={() => colorHandler(colorsEnum.orange)}
            />
            <ColorIcon
              fill={colorsEnum.green}
              title="choose green poo color"
              stroke={getStroke(colorsEnum.green)}
              onClick={() => colorHandler(colorsEnum.green)}
            />
            <ColorIcon
              fill={colorsEnum.yellow}
              title="choose yellow poo color"
              stroke={getStroke(colorsEnum.yellow)}
              onClick={() => colorHandler(colorsEnum.yellow)}
            />
            <ColorIcon
              fill={colorsEnum.darkbrown}
              title="choose darkbrown poo color"
              stroke={getStroke(colorsEnum.darkbrown)}
              onClick={() => colorHandler(colorsEnum.darkbrown)}
            />
            <ColorIcon
              fill={colorsEnum.white}
              title="choose white poo color"
              stroke={getStroke(colorsEnum.white)}
              onClick={() => colorHandler(colorsEnum.white)}
            />
            <ColorIcon
              fill={colorsEnum.red}
              title="choose red poo color"
              stroke={getStroke(colorsEnum.red)}
              onClick={() => colorHandler(colorsEnum.red)}
            />
          </div>
          <div id="addpoo_icon">
            <MoodyPoo shape={shape} color={color} mood={mood} id="addpoo" />
            <div id="pootext">
              <h6>
                {shapesEnum[shape.id]} {colorsEnum[color.id]}
              </h6>
              <Language topic="shape" id={shape.id} />
            </div>
          </div>
          <div id="addpoo_shapes">
            <ShapeNuts
              onClick={() => shapeChangeHandler(shapesEnum.nuts)}
              fill={getFill(shapesEnum.nuts)}
            />
            <ShapeLumpy
              onClick={() => shapeChangeHandler(shapesEnum.lumpy)}
              fill={getFill(shapesEnum.lumpy)}
            />
            <ShapeCracky
              onClick={() => shapeChangeHandler(shapesEnum.cracky)}
              fill={getFill(shapesEnum.cracky)}
            />
            <ShapeSausage
              onClick={() => shapeChangeHandler(shapesEnum.sausage)}
              fill={getFill(shapesEnum.sausage)}
            />
            <ShapeBlobs
              onClick={() => shapeChangeHandler(shapesEnum.blobs)}
              fill={getFill(shapesEnum.blobs)}
            />
            <ShapeFluffy
              onClick={() => shapeChangeHandler(shapesEnum.fluffy)}
              fill={getFill(shapesEnum.fluffy)}
            />
            <ShapeWatery
              onClick={() => shapeChangeHandler(shapesEnum.watery)}
              fill={getFill(shapesEnum.watery)}
            />
          </div>
        </div>
        <Mood
          addMood={addPooHandler}
          changeMood={changeMoodHandler}
          type="poo"
        />
        <PooResultsLastSeven />
        <MaxReached show={modal} setModal={setModal} />
      </div>
    </React.Fragment>
  );
};

export default withRouter(AddPoo);
