import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import "./App.scss";
import PooStart from "./components/PooStart";
import AddPoo from "./components/AddPoo";
import Info from "./components/Info";
import { AuthProvider } from "./components/Auth";
import Login from "./components/Login";
import Results from "./components/Results";
import AddDetails from "./components/AddDetails";
import { db } from "./base";
import apiConfig from "./ApiKeys";
import Mood from "./components/Mood";
import Settings from "./components/Settings";
import Details from "./components/Details";
import { useServiceWorker } from "./useServiceWorker";
import DashBoard from "./components/DashBoard";

const App = () => {
  const { isUpdateAvailable, updateAssets } = useServiceWorker();

  // try to add weather data from OpenWeatherMap
  const addWeather = pooAll => {
    const { location } = pooAll;
    if (location && location.latitude && location.longitude) {
      const weatherURL = `https://api.openweathermap.org/data/2.5/weather?lat=${location.latitude}&lon=${location.longitude}&units=metric&APPID=${apiConfig.owmKey}`;
      fetch(weatherURL)
        .then(res => res.json())
        .then(data => {
          const weather = {
            clouds: data.clouds ? data.clouds : null,
            main: data.main ? data.main : null,
            weather: data.weather ? data.weather : null,
            wind: data.wind ? data.wind : null,
            name: data.name ? data.name : null
          };
          pooAll.weather = weather;
          addDataObjectToDb(pooAll);
        })
        .catch(err => {
          console.log(err);
          //add without weather
          addDataObjectToDb(pooAll);
        });
    }
  };

  //Add record to db
  const addDataObjectToDb = dataObject => {
    if (validate(dataObject)) {
      db.collection("Poo")
        .add({
          ...dataObject,
          createdAt: new Date()
        })
        .then(() => {
          console.log("record created");
        })
        .catch(err => {
          console.log("error", err);
        });
    }
  };

  //edit db record
  const editDataObjectToDb = dataObject => {
    if (validate(dataObject)) {
      const ref = db.collection("Poo").doc(dataObject.key);
      delete dataObject.key;
      //on edit no new location data
      delete dataObject.location;
      ref
        .update({
          ...dataObject
        })
        .then(() => {
          console.log("data updated");
        })
        .catch(err => {
          console.log("error", err);
        });
    }
  };

  //remove from db
  const deleteDocumentFromDb = key => {
    if (key) {
      const ref = db.collection("Poo").doc(key);
      ref
        .delete()
        .then(() => {
          console.log("data deleted");
        })
        .catch(err => {
          console.log("error", err);
        });
    }
  };

  //validate data
  const validate = dataObject => {
    let ret = false;
    if (dataObject) {
      if (dataObject.moodId) {
        ret = true;
      }
      if (dataObject.colorId) {
        //to lowercase
        dataObject.colorId = dataObject.colorId.toLowerCase();
      }
      if (dataObject.location) {
        ret = dataObject.location.latitude ? true : false;
      }
      if (dataObject.details) {
        ret = dataObject.details.emotions ? true : false;
      }
      if (dataObject.weather) {
        ret = dataObject.weather.clouds ? true : false;
      }
    }
    return ret;
  };

  //handle submitted data from poo, mood or details
  const addPooHandler = dataObject => {
    //edit mode
    if (dataObject.key) {
      editDataObjectToDb(dataObject);
    } else if (
      dataObject.location &&
      dataObject.location.latitude &&
      dataObject.location.longitude
    ) {
      addWeather(dataObject);
    } else {
      //add without location and weather
      delete dataObject.location;
      addDataObjectToDb(dataObject);
    }
  };

  //delete handler
  const deleteHandler = key => {
    if (key) {
      deleteDocumentFromDb(key);
    }
  };

  //edit mood handler
  const editMoodHandler = dataObject => {
    if (dataObject) {
      editDataObjectToDb(dataObject);
    }
  };

  return (
    <React.Fragment>
      {isUpdateAvailable && (
        <div style={{ textAlign: "center", msrginTop: 44 }}>
          <p>A new version of this app is available!</p>
          <button className="btn" type="button" onClick={updateAssets}>
            Update now
          </button>
        </div>
      )}

      <AuthProvider>
        <BrowserRouter>
          <div className="App">
            <Route
              exact
              path="/"
              render={props => <PooStart addMood={addPooHandler} />}
            />
            <Route path="/info" render={props => <Info />} />
            <Route
              path="/results"
              render={props => <Results deletePoo={deleteHandler} />}
            />
            <Route
              path="/addpoo"
              render={props => <AddPoo addPoo={addPooHandler} />}
            />
            <Route
              path="/editpoo/:id"
              render={props => <AddPoo addPoo={addPooHandler} />}
            />
            <Route
              path="/editmood"
              render={props => <Mood editMood={editMoodHandler} type="mood" />}
            />
            <Route path="/login" component={Login} />
            <Route path="/dashboard" component={DashBoard} />
            <Route path="/settings" render={props => <Settings />} />
            <Route path="/details/:id" render={props => <Details />} />
            <Route
              path="/adddetails"
              render={props => <AddDetails addDetails={addPooHandler} />}
            />
          </div>
        </BrowserRouter>
      </AuthProvider>
    </React.Fragment>
  );
};

export default App;
