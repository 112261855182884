import React, { useContext } from "react";
import { SideNav, SideNavItem, Icon } from "react-materialize";
import logo from "../media/moodypoo.png";
import grass from "../media/grass.png";
import { AuthContext } from "./Auth.js";
import app from "../base";
import { Redirect, Link } from "react-router-dom";
import Language from "./Language";

const Nav = props => {
  const trigger = (
    <div>
      <Icon className="menu-icon">menu</Icon>
    </div>
  );

  const { user } = useContext(AuthContext);

  return (
    <SideNav trigger={trigger} options={{ closeOnClick: true, edge: "left" }}>
      <SideNavItem
        userView
        user={{
          background: grass,
          image: logo,
          name: user ? user.displayName : ""
        }}
      />
      <li>
        <Link to="/info" className="waves-effect">
          Info
        </Link>
      </li>
      {user ? (
        <React.Fragment>
          <li>
            <Link to="/results" className="waves-effect">
              <Language topic="ui" id="NavResults" />
            </Link>
          </li>
          <li>
            <Link to="/dashboard" className="waves-effect">
              <Language topic="ui" id="DashBoardTitle" />
            </Link>
          </li>
          <li onClick={() => app.auth().signOut()}>
            <Link to="/">
              {" "}
              <Language topic="ui" id="NavSignOut" />
            </Link>
          </li>
        </React.Fragment>
      ) : (
        <li waves href="/" onClick={() => <Redirect to="/login" />}>
          <Link to="/">
            <Language topic="ui" id="NavSignIn" />
          </Link>
        </li>
      )}
      <SideNavItem divider />
      <li>
        <Link to="/settings">
          <i className="material-icons">settings</i>
          <Language topic="ui" id="NavSettings" />
        </Link>
      </li>
    </SideNav>
  );
};

export default Nav;
