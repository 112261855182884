import React, { useContext, useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Report from "./Report";
import { AuthContext } from "./Auth.js";
import { useCookies } from "react-cookie";
import { getLanguageText } from "./Language";
import { db } from "../base";
import Logo from "../media/moodypoo.png";

const Settings = () => {
  const { language, setLanguage, user } = useContext(AuthContext);
  const [cookies, setCookie] = useCookies(["moodypoo"]);
  const [redirect, setRedirect] = useState(false);

  const onLanguageChange = e => {
    setLanguage(e.target.value);
    setCookie("moodypoo", e.target.value, { path: "/" });
    if (user) {
      const usersRef = db.collection("Users").doc(user.uid);
      usersRef.set(
        {
          language: e.target.value
        },
        { merge: true }
      );
    }
  };

  useEffect(() => {
    if (!user) setRedirect(true);
    if (cookies && cookies.moodypoo) setLanguage(cookies.moodypoo);
    // eslint-disable-next-line
  }, []);

  if (redirect) {
    return <Redirect to="/" />;
  }

  return (
    <React.Fragment>
      <div className="linkContainer">
        <Link to="/">
          <i className="material-icons big">chevron_left</i>
        </Link>
        <h6>{getLanguageText("ui", "NavSettings", language)}</h6>
        <div>
          <img id="logo" alt="moodypoo" src={Logo} style={{ width: 30 }} />
        </div>
      </div>
      <ul className="settings collection">
        <li className="collection-item avatar">
          <i className="material-icons circle green">insert_chart</i>
          <span className="title">
            {getLanguageText("ui", "SettingsDownloadReport", language)}
          </span>
          <p>
            <Report />
          </p>
          <div className="secondary-content">
            <i className="material-icons small">file_download</i>
          </div>
        </li>
        <li className="collection-item avatar">
          <i className="material-icons circle red">language</i>
          <span className="title">
            {getLanguageText("ui", "SettingsLanguage", language)}
          </span>
          <select id="lang" value={language} onChange={onLanguageChange}>
            <option value="en">English</option>
            <option value="nl">Nederlands</option>
          </select>
          <div className="secondary-content">
            <i className="material-icons small">flag</i>
          </div>
        </li>
        <li className="collection-item avatar">
          <i className="material-icons circle blue">new_releases</i>
          <span className="title">
            {getLanguageText("ui", "SettingsNewVersion", language)}
          </span>
          <p>
            <a href="/">
              {getLanguageText("ui", "SettingsNewVersionCheck", language)}
            </a>
          </p>
          <div className="secondary-content">
            <i className="material-icons small">autorenew</i>
          </div>
        </li>
      </ul>
    </React.Fragment>
  );
};

export default Settings;
