import React, { useContext } from "react";
import Chart from "react-apexcharts";
import { AuthContext } from "./Auth.js";
import shapesEnum from "./shapesEnum";
import { getLanguageText } from "./Language";

let sausageData,
  blobsData,
  crackyData = null;

const MoodChart = props => {
  const { data, language } = useContext(AuthContext);
  const { shapes, css } = props;

  const getSausage = data => {
    const result = data.filter(res => {
      return res.shapeId === shapesEnum.sausage;
    });
    return result;
  };
  const getBlobs = data => {
    const result = data.filter(res => {
      return res.shapeId === shapesEnum.blobs;
    });
    return result;
  };
  const getCracky = data => {
    const result = data.filter(res => {
      return res.shapeId === shapesEnum.cracky;
    });
    return result;
  };

  const getDataArray = data => {
    const dataArray =
      data &&
      data.slice(0, 80).map(a => {
        return [a.createdAt.toDate().getTime(), a.moodId - 3];
      });
    return dataArray;
  };

  const arrayData = data && getDataArray(data);

  if (shapes && data) {
    sausageData = getDataArray(getSausage(data));
    blobsData = getDataArray(getBlobs(data));
    crackyData = getDataArray(getCracky(data));
  }

  const moodChart = {
    options: {
      title: {
        text: getLanguageText("ui", "Mood2", language)
      },
      legend: {
        fontSize: 14
      },
      chart: {
        width: "100%",
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        type: "datetime",
        max: arrayData && arrayData[0] && arrayData[0][0],
        min:
          arrayData &&
          arrayData.length > 0 &&
          arrayData[arrayData.length - 1][0],
        tickAmount: 6
      },
      yaxis: {
        tickAmount: 5,
        labels: {
          formatter: value => {
            let ret = "";
            switch (value) {
              case -2:
                ret = "bad ";
                break;
              case -1:
                ret = "not good";
                break;
              case 0:
                ret = "okay";
                break;
              case 1:
                ret = "good";
                break;
              case 2:
                ret = "very good";
                break;
              default:
                ret = "";
                break;
            }
            return ret;
          }
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.5,
          opacityTo: 0.9,
          stops: [0, 100]
        }
      }
    },
    series: []
  };

  if (shapes) {
    const sausage = {
      name: "Sausage",
      data: sausageData && [...sausageData]
    };
    moodChart.series.push(sausage);
    const blobs = {
      name: "Blobs",
      data: blobsData && [...blobsData]
    };
    moodChart.series.push(blobs);
    const cracky = {
      name: "Cracky",
      data: crackyData && [...crackyData]
    };
    moodChart.series.push(cracky);
  } else {
    const s = {
      name: "Mood",
      data: arrayData && [...arrayData]
    };
    moodChart.series.push(s);
  }

  return (
    <div className={css}>
      {data && data.length > 5 ? (
        <Chart
          options={moodChart.options}
          series={moodChart.series}
          type="area"
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default MoodChart;
