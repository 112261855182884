import React, { useContext, useState, useEffect } from "react";
import { Button, Icon } from "react-materialize";
import Background from "../media/moodypoostart.svg";
import { Link, Redirect } from "react-router-dom";
import Mood from "./Mood";
import Nav from "./Nav";
import { AuthContext } from "./Auth.js";
import MoodChart from "./MoodChart";
import MaxReached, { maxIsReached } from "./MaxReached.js";
import { useCookies } from "react-cookie";
import Logo from "../media/moodypoo.png";

const PooStart = props => {
  const getStyle = () => {
    return {
      backgroundImage: `url(${Background})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      maxHeight: 420,
      minHeight: 270,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundSize: "contain"
    };
  };

  const { addMood } = props;

  const {
    user,
    setMaxRecords,
    data,
    dataChanged,
    setDataChanged,
    setPoo,
    setLanguage
  } = useContext(AuthContext);

  const [modal, setModal] = useState(false);
  const [cookies] = useCookies(["moodypoo"]);

  //id db data was changed count number of records today from user. max 10 entries per day allowed
  if (dataChanged) {
    const today = new Date();
    const todayFull = today.getDate() + today.getFullYear();
    const tooMuch =
      data &&
      data.filter(res => {
        return (
          res.createdAt.toDate().getDate() +
            res.createdAt.toDate().getFullYear() ===
          todayFull
        );
      });
    setMaxRecords(tooMuch && tooMuch.length);
    setDataChanged(false);
    setPoo(null);
  }

  //handle mood pass to app js
  const AddMoodHandler = moodObject => {
    if (maxIsReached) {
      setModal(true);
    } else {
      addMood(moodObject);
    }
  };

  useEffect(() => {
    if (cookies && cookies.moodypoo) setLanguage(cookies.moodypoo);
    // eslint-disable-next-line
  }, []);

  if (!user) {
    return <Redirect to="/Login" />;
  }

  return (
    <React.Fragment>
      <div className="linkContainer">
        <Nav />
        <h6>MoodyPoo</h6>
        <div>
          <img id="logo" alt="moodypoo" src={Logo} style={{ width: 30 }} />
        </div>
      </div>
      <div className="container poostart">
        <div style={getStyle()}>
          <Link
            to={{
              pathname: "/addpoo",
              id: "poostart"
            }}
          >
            <Button
              floating
              large
              className="red"
              waves="light"
              icon={<Icon>add</Icon>}
              style={{ marginTop: 115, width: 75, height: 75 }}
            />
          </Link>
        </div>

        <MoodChart />
        <Mood addMood={AddMoodHandler} type="mood" />
        <MaxReached show={modal} setModal={setModal} />
      </div>
    </React.Fragment>
  );
};

export default PooStart;
