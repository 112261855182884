import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import firebaseConfig from "./firebaseConfig";

const app = firebase.initializeApp(firebaseConfig);

export const db = app.firestore();

// enable offline data
db.enablePersistence().catch(function(err) {
  if (err.code === "failed-precondition") {
    // probably multible tabs open at once
    console.log("persistance failed");
  } else if (err.code === "unimplemented") {
    // lack of browser support for the feature
    console.log("persistance not available");
  }
});

export default app;
