import React, { useState, useEffect } from "react";
import ReactMapGL, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import ApiKeys from "../ApiKeys";
import MoodyPoo from "./MoodyPoo";
import colorsEnum from "./colorsEnum";

const Locations = props => {
  const { latitude, longitude, pooObject } = props;
  const [state, setState] = useState({
    viewport: {
      width: "100%",
      height: 400,
      latitude: 80,
      longitude: 80,
      zoom: 11
    }
  });

  useEffect(() => {
    const view = state.viewport;
    setState({
      viewport: { ...view, latitude: latitude, longitude: longitude }
    });
    // eslint-disable-next-line
  }, [latitude]);

  return (
    <div className="locations">
      <ReactMapGL
        {...state.viewport}
        mapStyle={ApiKeys.mapUrlKey}
        //onViewportChange={viewport => setState({ viewport })}
      >
        <Marker
          latitude={latitude}
          longitude={longitude}
          offsetLeft={-20}
          offsetTop={-10}
        >
          <MoodyPoo
            css="moodypoo_small"
            mood={{ id: pooObject && pooObject.moodId }}
            shape={{
              id: pooObject && pooObject.shapeId ? pooObject.shapeId : 0
            }}
            color={{
              id:
                pooObject && pooObject.colorId
                  ? colorsEnum[pooObject.colorId.toLowerCase()]
                  : "#ffffff"
            }}
          />
        </Marker>
      </ReactMapGL>
    </div>
  );
};

export default Locations;
