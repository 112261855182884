import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "./Auth.js";
import Chart from "react-apexcharts";
import shapesEnum from "./shapesEnum";
import colorsEnum from "./colorsEnum";
import MoodChart from "./MoodChart";
import { Link, Redirect } from "react-router-dom";
import { getLanguageText } from "./Language";
import Logo from "../media/moodypoo.png";
import EmotionsBubleChart from "./EmotionsBubleChart";

const DashBoard = () => {
  const { data, language, user } = useContext(AuthContext);
  const [countersShapes, setCountersShapes] = useState(null);
  const [countersColors, setCountersColors] = useState(null);
  const [redirect, setRedirect] = useState(false);

  const counterShapes = (data, shape) => {
    if (!data) return null;
    const result = data.filter(res => {
      return res.shapeId === shape;
    });
    return result.length;
  };

  const counterColors = (data, color) => {
    if (!data) return null;
    const result = data.filter(res => {
      return res.colorId === color;
    });
    return result.length;
  };

  const renderShapes = data => {
    const shapeCounterArray = [];
    for (let index = 1; index < 8; index++) {
      const count = counterShapes(data, index);
      shapeCounterArray.push(count);
    }
    setCountersShapes(shapeCounterArray);
  };
  const renderColors = data => {
    const colorCounterArray = [];
    for (let index = 1; index < 8; index++) {
      const colorId = colorsEnum[index];
      const count = counterColors(data, colorId.toLowerCase());
      colorCounterArray.push(count);
    }
    setCountersColors(colorCounterArray);
  };

  useEffect(() => {
    if (!user) setRedirect(true);
  }, [user]);

  useEffect(() => {
    if (data) {
      renderShapes(data);
      renderColors(data);
    }
    // eslint-disable-next-line
  }, [data]);

  if (redirect) {
    return <Redirect to="/" />;
  }

  const shapesChart = {
    options: {
      title: {
        text: getLanguageText("ui", "PooShape", language)
      },
      legend: {
        position: "bottom",
        fontSize: 14,
        itemMargin: {
          horizontal: 5,
          vertical: 5
        }
      },
      tooltip: {
        enabled: false
      },
      labels: [
        shapesEnum[shapesEnum.nuts],
        shapesEnum[shapesEnum.lumpy],
        shapesEnum[shapesEnum.cracky],
        shapesEnum[shapesEnum.sausage],
        shapesEnum[shapesEnum.blobs],
        shapesEnum[shapesEnum.fluffy],
        shapesEnum[shapesEnum.watery]
      ],
      colors: [
        "#40250d",
        "#4a2b10",
        "#633917",
        "#74431B",
        "#825907",
        "#b87e09",
        "#d9b979"
      ],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true
            }
          }
        }
      }
    },
    series: countersShapes ? countersShapes : [1]
  };

  const colorsChart = {
    options: {
      title: {
        text: getLanguageText("ui", "PooColor", language)
      },
      legend: {
        position: "bottom",
        fontSize: 14,
        itemMargin: {
          horizontal: 5,
          vertical: 5
        }
      },
      tooltip: {
        enabled: false
      },
      labels: [
        getLanguageText("colors", colorsEnum.brown, language),
        getLanguageText("colors", colorsEnum.orange, language),
        getLanguageText("colors", colorsEnum.green, language),
        getLanguageText("colors", colorsEnum.yellow, language),
        getLanguageText("colors", colorsEnum.darkbrown, language),
        getLanguageText("colors", colorsEnum.white, language),
        getLanguageText("colors", colorsEnum.red, language)
      ],
      colors: [
        colorsEnum.brown,
        colorsEnum.orange,
        colorsEnum.green,
        colorsEnum.yellow,
        colorsEnum.darkbrown,
        colorsEnum.white,
        colorsEnum.red
      ],
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            labels: {
              show: false
            }
          }
        }
      }
    },
    series: countersColors ? countersColors : [1]
  };

  return (
    <div>
      <div className="linkContainer">
        <Link to="/">
          <i className="material-icons big">chevron_left</i>
        </Link>
        <h6>{getLanguageText("ui", "DashBoardTitle", language)}</h6>
        <div>
          <img id="logo" alt="moodypoo" src={Logo} style={{ width: 30 }} />
        </div>
      </div>
      <div className="dashboardContainer container">
        <Chart
          id="shapes"
          options={shapesChart.options}
          series={shapesChart.series}
          type="donut"
          className="chart1"
        />
        <Chart
          id="colors"
          options={colorsChart.options}
          series={colorsChart.series}
          type="donut"
          className="chart2"
        />
        <MoodChart shapes={true} css="chart3" />
        <EmotionsBubleChart css="chart4" />
      </div>
    </div>
  );
};

export default DashBoard;
