import React, { useEffect, useState } from "react";
import app, { db } from "../base";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [data, setData] = useState(null);
  const [poo, setPoo] = useState(null);
  const [maxRecords, setMaxRecords] = useState(0);
  const [dataChanged, setDataChanged] = useState(false);
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    const unsubscribe = app.auth().onAuthStateChanged(user => {
      setCurrentUser(user);
      if (user) {
        getSetDataListener(user.uid);
        getUserData(user.uid);
      }
    });
    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  const getUserData = uid => {
    const usersRef = db.collection("Users").doc(uid);
    usersRef.get().then(doc => {
      if (!doc.exists) {
        usersRef.set(
          {
            language: language
          },
          { merge: true }
        );
      } else {
        if (doc.data()) {
          setUserData(doc.data());
          setLanguage(doc.data().language);
        }
      }
    });
  };

  const getSetDataListener = uid => {
    //getdata from firestore and listen to changes
    db.collection("Poo")
      .where("userId", "==", uid)
      .orderBy("createdAt", "desc")
      .limit(100)
      .onSnapshot(snapshot => {
        const dataFb = snapshot.docs.map(doc => {
          const data = doc.data();
          const key = doc.id;
          return { key, ...data };
        });
        dataFb && dataFb.length > 0 ? setData(dataFb) : setData(null);
        setDataChanged(true);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        user: currentUser,
        userData,
        data,
        poo,
        setPoo,
        maxRecords,
        setMaxRecords,
        dataChanged,
        setDataChanged,
        language,
        setLanguage
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
