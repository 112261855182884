const firebaseConfig = {
  apiKey: "AIzaSyANESY9OlTQT58aEuaPSV97hbYx7Q06OFk",
  authDomain: "moodypoo-9219f.firebaseapp.com",
  databaseURL: "https://moodypoo-9219f.firebaseio.com",
  projectId: "moodypoo-9219f",
  storageBucket: "",
  messagingSenderId: "746273598934",
  appId: "1:746273598934:web:6faea28cd24427be428877",
  measurementId: "G-BHSRYCPN76"
};

export default firebaseConfig;
